import { createClient } from "./client";

export const getUser = token => {
  return new Promise((resolve, reject) => {
    createClient(token)
      .get("/me")
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
