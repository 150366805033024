import React, { Component } from "react";

import Loader from "loader";

import { getUser } from "api";

export const withAuth = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = { loaded: false, currentUser: null, accessToken: null };
    }

    componentDidMount() {
      const {
        match: {
          params: { accessToken }
        }
      } = this.props;

      getUser(accessToken)
        .then(currentUser => {
          this.setState({ loaded: true, currentUser, accessToken });
        })
        .catch(error => {
          console.error(error);
          this.setState({ loaded: true });
        });
    }

    render() {
      const { loaded, currentUser, accessToken } = this.state;

      if (!loaded) {
        return <Loader />;
      }

      if (!currentUser) {
        return <p>Unauthorized</p>;
      }

      return (
        <WrappedComponent
          accessToken={accessToken}
          currentUser={currentUser}
          {...this.props}
        />
      );
    }
  };
};
