import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { withAuth } from "./auth";
import Loader from "./loader";

const Shipments = React.lazy(() => import("./shipments"));

const NotFound = () => <p>Page not found</p>;

export const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            path="/:accessToken/shipments"
            component={withAuth(Shipments)}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
