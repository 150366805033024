import { createClient } from "./client";

export const fetchShipments = token => {
  return createClient(token).get("/shipments/current");
};

export const fetchShipmentPacks = (token, number) => {
  return createClient(token).get(`/shipments/${number}/packs`);
};

export const moveShipmentPacks = (token, from, to, numbers) => {
  return createClient(token).patch("/shipments/update-packs", {
    from,
    to,
    numbers
  });
};
