import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const timeout = 20000;

const createClient = (token) => {
  return axios.create({
    baseURL,
    timeout,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export { createClient };
